import { disableTopAndBottomArrows } from './change-community-page-order';

$(document).ready(function () {
    $('#add-community-page-input').val("");

    // Add a new community page, when the form button is clicked
    $('#add-community-page').on('click', function () {
        let community_id = window.location.pathname.split("/")[2];
        let page_name = $('#add-community-page-input').val();
        let params = JSON.parse('{"community_id": "' + community_id + '", "page_name": "' + page_name + '"}');

        // Submit page info
        $.get('/foreninger/side/ny', params, function (data) {
            if (data.status !== "Error") {
                // Clear input field
                $('#add-community-page-input').val("");

                // Create new nodes
                let navDiv = document.createElement('div');
                let navLink = document.createElement('a');
                let linkText = document.createTextNode(data.page_name);
                let orderDiv = document.createElement('div');
                let upImg = document.createElement('img');
                let downImg = document.createElement('img');

                // Append new nodes
                navDiv.setAttribute('class', 'community-page-nav-item ' + data.page_order);
                navLink.setAttribute('href', '/foreninger/' + data.community_id + '/side/' + data.page_id);
                orderDiv.setAttribute('class', 'change-page-order');
                upImg.setAttribute('class', 'move-up');
                upImg.setAttribute('src', '/icons/chevron-up.svg');
                downImg.setAttribute('class', 'move-down');
                downImg.setAttribute('src', '/icons/chevron-down.svg');
                navLink.append(linkText);
                navDiv.append(navLink);
                orderDiv.append(upImg);
                orderDiv.append(downImg);
                navDiv.append(orderDiv);
                $('#community-page-nav').append(navDiv);
                disableTopAndBottomArrows();
            } else{
                alert('Der opstod en fejl. Sørg for at sidens navn er mindst 5 tegn langt')
            }
        });
    });
});
