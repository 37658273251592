$(document).ready(function () {
    positionElements();

    $('#sidebar').on('mouseover', function () {
        document.body.style.overflow='hidden';
    });

    $('#sidebar').on('mouseleave', function () {
        document.body.style.overflow='auto';
    });
});

$(window).scroll(function () {
    positionElements();
});

$(window).resize(function () {
    positionElements();
})

// Position navbar and scrollbar based on scroll position
let positionElements = function () {
    let scrollPos =  $(window).scrollTop();
    let headerHeight = $("#pageHeader").outerHeight();
    let bodyWidth = $('body').outerWidth();

    if (bodyWidth > 1200) {
        positionSidebar(50, scrollPos, headerHeight);
        positionPageContainer(50, scrollPos, headerHeight);
    } else {
        positionSidebar(0, scrollPos, headerHeight);
        positionPageContainer(0, scrollPos, headerHeight);
    }
}

// Handle sidebar position
let positionSidebar = function (offset, scrollPos, headerHeight) {
    let visibleHeader = headerHeight - scrollPos;

    if (visibleHeader > 0) {
        $("#sidebar, #pageDimmer").css("height", "calc(100vh - " + (visibleHeader + offset) + "px)");
        $("#sidebar, #pageDimmer").css("top", visibleHeader + offset + "px");
    } else {
        $("#sidebar, #pageDimmer").css("height", "calc(100vh - " + offset + "px)");
        $("#sidebar, #pageDimmer").css("top", offset + "px");
    }
}

// Handle navbar and page container position
let positionPageContainer = function (offset, scrollPos, headerHeight) {
    if (scrollPos > headerHeight) {
        $("#navbar").css("position", "fixed");
        $("#navbar").css("top", "0");
        $("#navbar").css("left", "0");
        $("#pageContainer").css("top", offset + "px");
        $("#footer").css("margin-top", offset + "px");
    } else {
        $("#navbar").css("position", "relative");
        $("#pageContainer").css("top", "0px");
        $("#footer").css("margin-top", "0px");
    }
}
