require("flatpickr");
import flatpickr from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da";

document.addEventListener('DOMContentLoaded', function() {
    let date = new Date();
    let futureYear = date.getFullYear() + 5;

    flatpickr('.flatpick-selector', {
        enableTime: true,
        time_24hr: true,
        minDate: date,
        maxDate: futureYear + "-12-31",
        "locale": Danish
    });
});