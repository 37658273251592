// Hide dropdown menus when the page is loaded
$(document).ready(function () {
    $(".dropdown-menu").hide();

    // Display a spinner when the search button is clicked
    $(".searchBar a").on("click", function () {
        setTimeout(function () {
            $(".searchBar a img").attr("src", "/animated_spinner.svg");
        }, 500);
    })

    // Show and hide a dropdown menu when its parent button is clicked
    $(".dropdown").on("click", function () {
        if ($(".dropdown-menu", this).is(":hidden")) {
            $(".dropdown-menu", this).slideDown();
        } else {
            $(".dropdown-menu", this).slideUp();
        }
    })
});