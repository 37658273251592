$(document).ready(function () {
    $('.editAlbumImageButton').on('click', function() {
        let parentElement = $(this).closest('.albumImageContainer');
        let parentId = parentElement.attr('class').split(/\s+/)[1];

        // show edit modal
        $('#updateImageModal').show();

        // Update form link
        let currentAction = $('form.edit_album_image').attr('action');
        let actionElements = currentAction.split('/');
        let newActionLink = actionElements.slice(0, actionElements.length - 1).join('/') + "/" + parentId;

        $('form.edit_album_image').attr('action', newActionLink);


        // clear form
        $("#imageUpdateForm .clearableField").each(function (i, target) {
            $(target).val('');
        });

        // if image already has info, add it to the form
        fillFormInput('photographer', parentId);
        fillFormInput('description', parentId);
        fillFormInput('year', parentId);

    });

    $('#updateImageModalBackground').on('click', function () {
       $('#updateImageModal').hide();
    });
});

const fillFormInput = (className, parentId) => {
    $('#imageUpdateForm  > .' + className + '-field > input').val(
        $('.albumImageContainer.' + parentId + ' > .imageDescription > .' + className).attr('class').split(' ').slice(1).join(' ')
    );
}