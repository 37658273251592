$(document).ready(function () {
    // Check all checkboxes
    $('.searchResultFilterCheckbox').each(function (index, element) {
        $(this).prop('checked', true);
    });

    $('#showEvents').on('click', function () {
        handleCheckpointClick('#showEvents', '#eventResults');
    });

    $('#showPages').on('click', function () {
        handleCheckpointClick('#showPages', '#pageResults');
    });

    $('#showNewsletters').on('click', function () {
        handleCheckpointClick('#showNewsletters', '#newsletterResults');
    });

    // Handle each checkbox
    let handleCheckpointClick = function(checkboxSelector, resultSelector) {
        if ($(checkboxSelector).is(':checked')) {
            $(resultSelector).show();
        } else {
            $(resultSelector).hide();
        }
    };
});