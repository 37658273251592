$(document).ready(function () {
    // Hide community page edit section on load
    $('#community-pages .card-tool').hide();
    $('#community-pages .change-page-order').hide();

    // Toggle visibility of edit section when the toggle button is clicked
    $('#community-pages .card-tool-toggle').on('click', function () {
        $('#community-pages .card-tool').toggle(100);
    });

    // Show change page order buttons when the toggle button is clicked
    $('#community-pages .card-tool-toggle').on('click', function () {
        $('#community-pages .change-page-order').toggle(100);
    });
});
