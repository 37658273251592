import { Beboerforeningen, Boldklubben, Flagforeningen, Hundeklubben, Jagtforeningen, Kleven, Strandbyen, Samvirke } from "./community-objects";

let allCommunities = [Strandbyen, Beboerforeningen, Kleven, Boldklubben, Flagforeningen, Jagtforeningen, Samvirke, Hundeklubben];
let currentActive = 1; // Must be between 1-4
let currentCommunities = allCommunities.slice((currentActive - 1 + 8) % 8, (currentActive + 2 + 8) % 8);

$(document).ready(function () {
    setup();

    // Increment index of active community
    // (Increment and decrement implemented as a circular buffer)
    let nextCommunity = function () {
        currentActive = (currentActive + 1) % 8;
    }

    // Decrement index of active community
    let prevCommunity = function () {
        currentActive = (currentActive - 1 + 8) % 8;
    }

    // Add the properties of the active communities to the correct elements
    let setCommunitiesShown = function () {
        $(".foreningImg").each(function (index) {
            $(this).attr("href", currentCommunities[index].pageLink);
            $(this).children("img").attr("src", currentCommunities[index].imgSrc);
            $(this).children("p").text(currentCommunities[index].title);
        });
        $(".bubble.active").removeClass("active");
        $(".bubble." + currentActive).addClass("active");
    };

    // Set 3 communities to be shown, based on the index of the active community
    let changeCurrentCommunities = function () {
       currentCommunities[0] = allCommunities[((currentActive - 1 + 8) % 8)];
       currentCommunities[1] = allCommunities[((currentActive + 8) % 8)];
       currentCommunities[2] = allCommunities[((currentActive + 1 + 8) % 8)];
       setCommunitiesShown();
    }

    // Enable navigation by clicking the navigation bubbles
    let changeWithBubbles = function (element) {
        currentActive = parseInt(element.attr("class").split(" ")[1]);
        changeCurrentCommunities();
    }

    // Show the next community when the arrow on the right is clicked
    $(".right-control img, .fi3").on("click", function () {
        nextCommunity();
        changeCurrentCommunities("right");
    });

    // Show the previous community when the arrow on the left is clicked
    $(".left-control img, .fi1").on("click", function () {
        prevCommunity();
        changeCurrentCommunities("left");
    });

    // Navigate to a community by pressing a navigation bubble
    $(".bubble").on("click", function () {
        changeWithBubbles($(this));
    });
})

// Setup the carousel on page load
let setup = function () {
    $(".foreningImg").each(function (index) {
        $(this).attr("href", currentCommunities[index].pageLink);
        $(this).children("img").attr("src", currentCommunities[index].imgSrc);
        $(this).children("p").text(currentCommunities[index].title);
    });

    $(".bubble.active").removeClass("active");
    $(".bubble." + currentActive).addClass("active");

    $(".pause-icon").hide();
}
