export let Beboerforeningen = {
    title: "Beboerforeningen",
    imgSrc: "/communityImages/beboerhuset.jpg",
    pageLink: "/foreninger/1"
}

export let Boldklubben= {
    title: "Ommel Boldklub",
    imgSrc: "/communityImages/boldklubben.jpg",
    pageLink: "/foreninger/3"
}

export let Kleven = {
    title: "Kleven Havn",
    imgSrc: "/communityImages/kleven.jpg",
    pageLink: "/foreninger/2"
}

export let Strandbyen = {
    title: "Strandbyen Havn",
    imgSrc: "/communityImages/strandbyen.jpg",
    pageLink: "/foreninger/6"
}

export let Jagtforeningen = {
    title: "Marstal Jagtforening",
    imgSrc: "/communityImages/jagtforeningen.jpg",
    pageLink: "/foreninger/5"
}

export let Hundeklubben = {
    title: "Ærø Hundeklub",
    imgSrc: "/communityImages/hundeklubben.jpg",
    pageLink: "https://www.aech.dk/"
}

export let Flagforeningen = {
    title: "Kløven Flagforening",
    imgSrc: "/communityImages/flagforeningen.jpg",
    pageLink: "/foreninger/4"
}

export let Samvirke = {
    title: "Ommel Samvirke",
    imgSrc: "/communityImages/samvirke.jpeg",
    pageLink: "/foreninger/8"
}
