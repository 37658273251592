$(document).ready(function () {
    let currentIndex = -1;
    let currentImageSource = "";
    let currentImageDescription = null;

    hideAlbumDimmer();

    // Show album dimmer when an image is clicked
    $('#albumImages > .albumImageContainer').on('click', function () {
        currentImageSource = $('img', this).attr('src');
        currentImageDescription = $('.imageDescription', this);
        currentIndex = parseInt($(this).attr('id'));

        showAlbumDimmer();
        setHighlightedImageSource(currentImageSource);
        setHighlightedImageDescription(currentIndex);
        setHighlightedImageIndex(currentIndex);
    }).children(".editAlbumImageButton").on('click', function () {
        return false;
    });

    // Hide album dimmer when it is clicked
    $('#albumDimmer > #dimmerBackground').on('click', function () {
       hideAlbumDimmer();
    });

    // Show the next image when the next image button is clicked
    $('#nextImage').on('click', function () {
       currentIndex = showNextImage(currentIndex);
    });

    // Show the previous image when the previous image button is clicked
    $('#prevImage').on('click', function () {
        currentIndex = showPrevImage(currentIndex);
    });

    // Show the next image when the right arrow key is pressed
    // and the previous image when the left arrow key is pressed
    $(document).on('keydown', function (event) {
       switch(event.which) {
           case 37: // Left key
               currentIndex = showPrevImage(currentIndex);
               break;
           case 39: // Right key
               currentIndex = showNextImage(currentIndex);
               break;
           case 27: // ESC key
               hideAlbumDimmer();
       }
    });

    $(window).resize(function () {
        setHighlightedImageSource(currentImageSource);
        setHighlightedImageDescription(currentIndex);
    });
});

// Hide album dimmer
let hideAlbumDimmer = () => {
    $('#albumDimmer').hide();
    $('.imageDescription').hide();
}

// Show album dimmer
let showAlbumDimmer = () => {
    $('#albumDimmer').show();
}

// Set the current index in the navigation box under the highlighted image
let setHighlightedImageIndex = (index) => {
    $('#highlightedImageIndex').text(index);
}

// Set the source of the highlighted image to the provided image source
let setHighlightedImageSource = (source) => {
    $('#albumDimmer > img').attr('src', source);
    $('#imagesOnPage').text(totalImages());
}

// Set the description of the highlighted image
let setHighlightedImageDescription = (index) => {
    $('.imageDescription').hide();

    if ($('#' + index + '.imageDescription').text().trim().length > 0) {
        $('#' + index + '.imageDescription').show();

        if ($(window).width() > 950) {
            $('#' + index + '.imageDescription').css("max-width", function () {
                return $('#albumDimmer > img').innerWidth() * 0.85;
            });

            $('#' + index + '.imageDescription').css("top", function () {
                return (
                    $('#albumDimmer > img').position().top +
                    $('#albumDimmer > img').innerHeight() -
                    $('#' + index + '.imageDescription').outerHeight() -
                    16
                );
            });
        } else {
            $('#' + index + '.imageDescription').css("width", "90vw");
            $('#' + index + '.imageDescription').css("margin-left", "0");

            $('#' + index + '.imageDescription').css("top", function () {
                return (
                    $('#albumDimmer > img').position().top + 16
                );
            });
        }


    }
}

// Set total number of images on page
let totalImages = () => {
    return $('.albumImageContainer').length;
}

// Show the image coming after the currently highlighted one
let showNextImage = (currentIndex) => {
    let nextElement = $('#' + currentIndex + '.albumImageContainer').next();
    let newSource;

    if (nextElement.length == 1) {
        newSource = nextElement.children('img:first').attr('src');
        currentIndex++;
    } else {
        newSource = $('.albumImageContainer:first').children('img:first').attr('src');
        currentIndex = parseInt($('.albumImageContainer:first').attr('id'));
    }

    setHighlightedImageSource(newSource);
    setHighlightedImageDescription(currentIndex);
    setHighlightedImageIndex(currentIndex);
    return currentIndex;
}

// Show the image coming before the currently highlighted one
let showPrevImage = (currentIndex) => {
    let prevElement = $('#' + currentIndex + '.albumImageContainer').prev();
    let newSource;

    if (prevElement.length == 1) {
        newSource = prevElement.children('img:first').attr('src');
        currentIndex--;
    } else {
        newSource = $('.albumImageContainer:last').children('img:first').attr('src');
        currentIndex = parseInt($('.albumImageContainer:last').attr('id'));
    }

    setHighlightedImageSource(newSource);
    setHighlightedImageDescription(currentIndex);
    setHighlightedImageIndex(currentIndex);
    return currentIndex;
}
