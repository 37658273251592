import {vanillaSelectBox} from "../../javascript/utility/vanilla-select-box";

// Multi select field configuration
$(document).ready(function () {
    let multiSelect = new vanillaSelectBox("#multiSelect");
    let caretRotation = 0;
    let label = "Hvem vil du modtage nyhedsbreve fra?";

    // Hide the original multi select field
    $("#multiSelect").hide();

    // New field setup
    $("#btn-group-\\#multiSelect.vsb-main > button").next().hide();
    $("#btn-group-\\#multiSelect.vsb-main > button > .title").text(label);
    setSpanColor(label);

    $("#btn-group-\\#multiSelect.vsb-main > button").on("click", function () {
        // Togle the field label visibility by adding and removing a class
        $("#btn-group-\\#multiSelect.vsb-main").next("label").toggleClass("multiSelectLabel");
        // Show label above the field if it's in use
        if ($("#btn-group-\\#multiSelect.vsb-main").next("label").hasClass("multiSelectLabel")) {
            $("#btn-group-\\#multiSelect.vsb-main").css("top", "7px");
        } else {
            $("#btn-group-\\#multiSelect.vsb-main").css("top", "0px");
        }

        // If the field is empty, insert label
        if ($("#btn-group-\\#multiSelect.vsb-main > button > .title").text().length == 0) {
            $("#btn-group-\\#multiSelect.vsb-main > button > .title").text(label);
        }

        // Rotate dropdown caret
        caretRotation = (caretRotation + 180) % 360;
        $(this).next().toggle();
        $("#btn-group-\\#multiSelect.vsb-main button > span.caret").css("transform", "rotate(" + caretRotation + "deg)");
    });

    $("#btn-group-\\#multiSelect.vsb-main, .vsb-menu").on("click", function () {
        setSpanColor(label);
    });
});

// Set span color based on if it acts as a label or if it contains values
let setSpanColor = function (label) {
    if ($("#btn-group-\\#multiSelect.vsb-main > button > .title").text() == label) {
        $("#btn-group-\\#multiSelect.vsb-main > button > span").css("color", "#727272");
    } else {
        $("#btn-group-\\#multiSelect.vsb-main > button > span").css("color", "#000");
    }
}
