// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Scripts containing page-wide configurations
require("global/element-positioning")
require("global/header")

// Utility scripts
require("utility/image-carousel")
require("utility/vanilla-select-box")
require("utility/flash-messages")
require("utility/album-image-navigation")
require("utility/tinymceInit")
require("utility/tinymce-da")
require("utility/flatpickr")
require("utility/month-picker")
require("utility/cursor-hints")
require("utility/add-community-page")
require("utility/change-community-page-order")
require("utility/toggle-community-page-edit")
require("utility/load-container")
require("utility/scale-images")
require("utility/error-messages")
require("utility/select-newsletter-communites")
require("utility/pagination-sizing")
require("utility/search-result-filters")
require("utility/card-toggle")
require("utility/sidebar-communities")
require("utility/album-spinner")
require("utility/add-divs-to-tables")
require("utility/album-edit-button")

// Home page specific scripts
require("home-page/community-carousel")
require("home-page/home-page")

// Page section scripts
require("page-sections/footer")
require("page-sections/content-sidebar")
require("page-sections/sidebar")
require("page-sections/show-event-summary")

// Javascript stylesheet imprt
require("../stylesheets/application.scss");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
