$(document).ready(() => {
    let countdown = 6;

    // Hide flash message on click
    $('.flash-message').on('click', function() {
        $(this).fadeOut(500);
    });

    // Show a countdown indicating when the flash message will disappear
    let flashCountdown = setInterval(function () {
        countdown -= 1;
        $(".flash-message #flash-count").text(countdown);

        if (countdown <= 0) {
            clearInterval(flashCountdown);
            $('.flash-message').fadeOut(500);
        }
    }, 1000);
});