export class ImageCarousel {
    constructor(containerId, imageFiles) {
        this.container = containerId;
        this.images = imageFiles;
        this.autoPlayInterval = null;

        this.createContainer();
        this.createNavigationElements();
        this.startAutoPlay();

        // Show the next image when the right navigation arrow is clicked
        $("#" + this.container + " > .right-control").on("click", function () {
            this.restartAutoPlay();
            this.next();
        }.bind(this));

        // Show the previous image when the left navigation arrow is clicked
        $("#" + this.container + " > .left-control").on("click", function () {
            this.restartAutoPlay();
            this.prev();
        }.bind(this));

        // Show the image related to the navigation bubble that has been clicked
        $("#" + this.container + " > .nav-bubbles" + " > .bubble").on("click", function (e) {
            let clickedBubbleId = e.currentTarget.className.split(" ")[1];

            this.restartAutoPlay();
            this.navigateByBubbleId(clickedBubbleId);
        }.bind(this));
    }

    // Setup the main image carousel container
    createContainer = () => {
        // Create inner container and get its width
        $("#" + this.container).append(`<div class="innerContainer"></div>`);

        // Add images to the inner container
        for (let i = 0; i < this.images.length; i++) {
            $("#" + this.container + " > .innerContainer").append(`<img src="${this.images[i]}">`);
        }

        // Give the first image the class 'active'
        $("#" + this.container + " > .innerContainer > img:first").addClass("active");

        this.setHeight();
    }

    // Get the outer width of the inner container
    getContainerWidth = () => {
        return $("#" + this.container + " > .innerContainer").outerWidth();
    }

    // Set the height of the main container
    setHeight = () => {
        $(".image-container").css("height", 9 / 16 * this.getContainerWidth() + "px");
    }

    // Create navigation arrows and bubbles
    createNavigationElements = () => {
        // Left arrow
        $("#" + this.container).append(`
            <div class="left-control">
                <img src="icons/chevron-left.svg">
            </div>`
        );

        // Right arrow
        $("#" + this.container).append(`
            <div class="right-control">
                <img src="icons/chevron-right.svg">
            </div>`
        );

        // Bubbles
        $("#" + this.container).append(`
            <div class="nav-bubbles"></div>
        `);

        for (let i = 0; i < this.images.length; i++) {
            $("#" + this.container + " > .nav-bubbles").append(`
                <div class="bubble ${i}"></div>
            `);
        }

        // Give the first bubble the class 'active'
        $("#" + this.container + " > .nav-bubbles > div:first").addClass("active");
    }

    // Show the next image and highlight the related navigation button
    next() {
        this.nextElement("#" + this.container + " > .innerContainer > img");
        this.nextElement("#" + this.container + " > .nav-bubbles > div");
    }

    // Show the previous image and highlight the related navigation button
    prev() {
        this.prevElement("#" + this.container + " > .innerContainer > img");
        this.prevElement("#" + this.container + " > .nav-bubbles > div");
    }

    // Show the image correlated to the bubble pressed and assign the 'active' class to both elements
    navigateByBubbleId(id) {
        $("#" + this.container + " > .nav-bubbles > .active").removeClass("active");
        $("#" + this.container + " > .nav-bubbles > ." + id).addClass("active");
        $("#" + this.container + " > .innerContainer > .active").removeClass("active");
        $("#" + this.container + " > .innerContainer > img:eq(" + id + ")").addClass("active");
    }

    // Select the next element relative to the selector
    nextElement(selector) {
        let activeElement = $(selector + ".active");
        activeElement.removeClass("active");
        !activeElement.next().length == 0 ?
            activeElement.next().addClass("active") :
            $(selector + ":first").addClass("active");
    }

    // Select the previous element relative to the selector
    prevElement(selector) {
        let activeElement = $(selector + ".active");
        activeElement.removeClass("active");
        !activeElement.prev().length == 0 ?
            activeElement.prev().addClass("active") :
            $(selector + ":last").addClass("active");
    }

    // Switch to the next image every 5 seconds
    startAutoPlay() {
        this.autoPlayInterval = setInterval(function () {
            this.next();
        }.bind(this), 5000);
    }

    // Stop automatically showing the next image every 5 seconds
    stopAutoPlay() {
        clearInterval(this.autoPlayInterval);
    }

    // Restart the autoplay interval
    restartAutoPlay() {
        this.stopAutoPlay();
        this.startAutoPlay();
    }
}
