require('tinymce/tinymce');
require('tinymce/themes/silver/theme');
require('tinymce/plugins/link');
require('tinymce/plugins/lists');
require('tinymce/plugins/image');
require('tinymce/plugins/paste');
require('tinymce/plugins/hr');
require('tinymce/plugins/table');
require('tinymce/skins/ui/oxide/content.min.css');
require('tinymce/skins/ui/oxide/skin.min.css');
require('tinymce/icons/default/icons');

tinymce.init({
    selector: 'textarea.tinymce',
    language_url: "./tinymce-da.js",
    language: "da",
    plugins: "link lists image paste hr table",
    skin: false,
    content_css: false,
    contextmenu: "paste | copy |link image inserttable | cell row column deletetable",
    contextmenu_never_use_native: true,
    advlist_bullet_styles: "square",
    paste_auto_cleanup_on_paste : true,
    paste_remove_styles: true,
    default_link_target: "_blank",
    paste_remove_styles_if_webkit: true,
    paste_strip_class_attributes: true,
    images_upload_url: '/community_page_uploader/image',
    menubar: "table",
    toolbar: [
        {
            name: 'history', items: [ 'undo', 'redo' ]
        },
        {
            name: 'styles', items: [ 'styleselect' ]
        },
        {
            name: 'formatting', items: [ 'bold', 'italic']
        },
        {
            name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ]
        },
        {
            name: 'indentation', items: [ 'outdent', 'indent' ]
        },
        {
            name: 'inserts', items: [ 'link' ]
        },
        {
            name: 'lists', items: ['bullist', "numlist"]
        },
        {
            name: 'images', items: [ 'image' ]
        },
        {
            name: "horizontal-rule", items: ["hr"]
        },
        {
            name: "table-items", items: ["table"]
        }
    ],
    link_assume_external_targets: true
});
