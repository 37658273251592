let elementsOnPage = [];

$(document).ready(function () {
    insertElements();
    scaleImages();

    $(window).resize(function () {
       scaleImages();
    });
});

let insertElements = function () {
    $('#left-container img').each(function (index, element) {
        elementsOnPage.push({
            originalWidth: $(element).innerWidth(),
            originalHeight: $(element).innerHeight(),
            selector: element,
            src: $(element).attr("src")
        });
    });
};

let scaleImages = function () {
    for (let i = 0; i < elementsOnPage.length; i++) {
        let currentElement = $(elementsOnPage[i].selector).filter("[src='" + elementsOnPage[i].src + "']");
        let originalWidth = elementsOnPage[i].originalWidth;
        let originalHeight = elementsOnPage[i].originalHeight;
        let containerWidth = $('#left-container').innerWidth();
        let currentWidth = currentElement.innerWidth();

        if (currentWidth > originalWidth) {
            currentWidth = originalWidth;

            let newHeight = (originalHeight / originalWidth) * currentWidth;
            currentElement.css({
                'width': originalWidth + 'px',
                'height': newHeight + 'px'
            });
        } else if (originalWidth > containerWidth) {
            currentWidth = containerWidth;

            let newHeight = (originalHeight / originalWidth) * currentWidth;
            currentElement.css({
                'width': containerWidth + 'px',
                'height': newHeight + 'px'
            });
        };
    }
};
