$(document).ready(function () {
    let hintClasses = ['sidebarItem'];

    $('.cursorHint').hide();

    // Show cursor hints when the cursor enters an element with a hint
    hintClasses.forEach(function (element, index) {
       $('.' + element).mouseenter(function (event) {
           let elementTop = $(this).offset().top;
           let cursorHintPosTop = elementTop - $(window).scrollTop() + (($(this).outerHeight() / 2) - 12);
           $('.cursorHint', this).css({
               'top': cursorHintPosTop + 'px',
               'left': $(this).offset().left + ($(this).outerWidth()) + 'px'
           });
           // Don't show hints for the sidebar, if the menu is extended
           let isSidebar = $(this).parent().parent().attr('id') == 'sidebar';
           if (!(isSidebar && $('#sidebar').hasClass('extended'))) {
               $('.cursorHint', this).show();
           }
       });

        $('.' + element).mouseleave(function () {
            $('.cursorHint', this).hide();
        });
    });
});