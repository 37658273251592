$(document).ready(function () {
    // Expand the sidebar when its toggle button is clicked
    $('.toggleContentSidebar').on('click', function () {
        let currentRightX = $(this).css('right');

        if (currentRightX == "0px") {
            $(this).addClass('extended');
            $('.contentSidebar').addClass('extended');
            $("img", this).attr("src", "/icons/chevron-right.svg");
        } else {
            $(this).removeClass('extended');
            $('.contentSidebar').removeClass('extended');
            $("img", this).attr("src", "/icons/chevron-left.svg");
        }
    });

    // Change border style when the toggle button is hovered
    $('.toggleContentSidebar').on('mouseenter', function () {
       $('.contentSidebar').css('border-left', '2px solid rgba(34, 36, 38, 0.5)');
    });

    $('.toggleContentSidebar').on('mouseleave', function () {
        $('.contentSidebar').css('border-left', '2px solid rgba(34, 36, 38, 0.15)');
    });

    $(window).scroll(function () {
        positionCard();
    });

    $(window).resize(function () {
        positionCard();
    });
});

let positionCard = function () {
    let scrollTop = $(window).scrollTop();

    if (scrollTop > 105) {
        if ($('body').innerWidth() > "1200") {
            $('.contentSidebar').css('top', '28px');
            $('.toggleContentSidebar').css('top', '76px');
        } else {
            $('.contentSidebar').css('top', '0px');
            $('.contentSidebar').css('margin-top', '0px');
            $('.toggleContentSidebar').css('top', '28px');
        }
    } else {
        if ($('body').innerWidth() > "1200") {
            $('.contentSidebar').css('top', (132 - scrollTop) + 'px');
            $('.toggleContentSidebar').css('top', (180 - scrollTop) + 'px');
        } else {
            $('.contentSidebar').css('top', (84 - scrollTop) + 'px');
            $('.contentSidebar').css('margin-top', '21px');
            $('.toggleContentSidebar').css('top', (130 - scrollTop) + 'px');
        }
    }
}