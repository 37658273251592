$(document).ready(function () {
    // Add has-events class to all elements with an event-box element
    $('.event-box').each(function (index, element) {
        let classArray = $(this).parent().attr("class").split(" ");
        let lastClass = classArray[classArray.length - 1];
        $(this).parent().removeClass(lastClass);
        $(this).parent().addClass('has-events');
        $(this).parent().addClass(lastClass);
    });

    $('.has-events').on('click', function (e) {
        // Show relevant event descriptions
        $('.has-events').not(this).find('.event-box').hide();
        $('.event-box', this).toggle();
        $('#calendarDimmer').toggle();

        // Position event descriptions
        let elementClasses = $(this).attr('class').split(' ');
        positionEventBoxes(elementClasses[elementClasses.length - 1]);

        e.stopPropagation();
    });

    $('.event-box').on('click', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', function () {
        $('.event-box').hide();
        $('#calendarDimmer').hide();
    });
});

let positionEventBoxes = function (parentElement) {
    if ($('.' + parentElement + ' .event-box').length > 1) {
        $('.' + parentElement + ' .event-box').each(function (index, element) {
            if (index > 0) {
                $(element).addClass('no-arrow');
                if ($('body').outerWidth() > 950) {
                    $(element).offset({ left: $(this).prev().offset().left })
                    $(element).css('top', $(this).prev().position().top + $(this).prev().outerHeight() + 'px');
                } else {
                    $(element).offset({ left: ($('body').innerWidth() / 2) - ($(this).innerWidth() / 2) })
                    $(element).css('top', $(this).prev().position().top + $(this).prev().outerHeight() + 'px');
                }
            }
        });
    }
}
