$(document).ready(function () {
    let today = new Date();
    let maxYear = today.getFullYear() + 5;
    let minYear = 2017;
    let path = window.location.pathname;
    let chosenYearElement = document.querySelector('.chosen-year');
    let chosenYear;

    if (chosenYearElement != null) {
        chosenYear = Number(chosenYearElement.textContent);
    }

    // Show the month picker when the calendar title is pressed
    $('.calendar-title').on('click', function() {
       $('.month-picker', this).toggleClass("visible");
    }).children().on('click', function() {
        return false;
    });

    // Close the month picker when you click outside it
    $(document).on('click', function (event) {
       if (!$(event.target).is('.calendar-title')) {
           $('.month-picker', this).removeClass("visible");
       };
    });

    // Increment the chosen year by pressing the right-chevron icon
    $('.next-year').on('click', function() {
        if (chosenYear + 1 <= maxYear) chosenYear += 1;
        updateChosenYear(chosenYear);
    });

    // Decrement the chosen year by pressing the left-chevron icon
    $('.prev-year').on('click', function() {
        if (chosenYear - 1 >= minYear) chosenYear -= 1;
        updateChosenYear(chosenYear);
    });

    // Go to the current month if 'reset' is clicked
    $('.month-picker .reset').on('click', function () {
        window.location.href = `${path}?dato=` + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    });

    // Go to the selected month and year, when a month is clicked
    $('.month-picker .month').on('click', function() {
       window.location.href = `${path}?dato=` + chosenYear + '-' + (zeroPad(Number($(this).attr('class').split(' ')[1]) + 1)) + '-01';
    });

    // Update the text value of the chosen year
    let updateChosenYear = function(year) {
        $('.chosen-year').text(year);
    }

    // Add zero-padding to numbers with a value below 10
    let zeroPad = function(number) {
        if (number < 10) {
            return "0" + String(number);
        };

        return String(number);
    };
});
