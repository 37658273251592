tinymce.addI18n('da',{
    "Redo": "Genopret",
    "Undo": "Fortryd",
    "Cut": "Klip",
    "Copy": "Kopier",
    "Paste": "Inds\u00e6t",
    "Select all": "V\u00e6lg alle",
    "New document": "Nyt dokument",
    "Ok": "Ok",
    "Cancel": "Fortryd",
    "Visual aids": "Visuel hj\u00e6lp",
    "Bold": "Fed",
    "Italic": "Kursiv",
    "Underline": "Understreget",
    "Strikethrough": "Gennemstreget",
    "Superscript": "H\u00e6vet",
    "Subscript": "S\u00e6nket",
    "Clear formatting": "Nulstil formattering",
    "Align left": "Venstrejusteret",
    "Align center": "Centreret",
    "Align right": "H\u00f8jrejusteret",
    "Justify": "Lige Margener",
    "Open link": "Åben link",
    "Bullet list": "Punktliste",
    "Numbered list": "Nummereret liste",
    "Decrease indent": "Formindsk indrykning",
    "Increase indent": "For\u00f8g indrykning",
    "Close": "Luk",
    "Formats": "Formater",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Din browser underst\u00f8tter ikke direkte adgang til clipboard. Benyt Ctrl+X\/C\/ keybord shortcuts i stedet for.",
    "Headers": "Overskrifter",
    "Header 1": "Overskrift 1",
    "Header 2": "Overskrift 2",
    "Header 3": "Overskrift 3",
    "Header 4": "Overskrift 4",
    "Header 5": "Overskrift 5",
    "Header 6": "Overskrift 6",
    "Headings": "Overskrifter",
    "Heading 1": "Overskrift 1",
    "Heading 2": "Overskrift 2",
    "Heading 3": "Overskrift 3",
    "Heading 4": "Overskrift 4",
    "Heading 5": "Overskrift 5",
    "Heading 6": "Overskrift 6",
    "Div": "Div",
    "Pre": "Pre",
    "Code": "Code",
    "Paragraph": "S\u00e6tning",
    "Blockquote": "Indrykning",
    "Inline": "Tekstformatering",
    "Blocks": "Normaltekst",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "S\u00e6t ind er indstillet til at inds\u00e6tte som ren tekst. Indhold bliver nu indsat uden formatering indtil du \u00e6ndrer indstillingen.",
    "Font Family": "Skrifttype",
    "Font Sizes": "Skriftst\u00f8rrelse",
    "Class": "Klasse",
    "Browse for an image": "S\u00f8g efter et billede",
    "OR": "ELLER",
    "Drop an image here": "Drop et billede her",
    "Upload": "Send",
    "Block": "Blok",
    "Align": "Tekstplacering",
    "Default": "Standard",
    "Circle": "Cirkel",
    "Disc": "Disk",
    "Square": "Kvadrat",
    "Lower Alpha": "Lower Alpha",
    "Lower Greek": "Lower Gr\u00e6sk",
    "Lower Roman": "Lower Roman",
    "Upper Alpha": "Upper Alpha",
    "Upper Roman": "Upper Roman",
    "Anchor": "Anchor",
    "Name": "Navn",
    "Id": "Id",
    "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id b\u00f8r starte med et bogstav, efterfulgt af bogstaver, tal, bindestreger, punktummer, koloner eller underscores.",
    "You have unsaved changes are you sure you want to navigate away?": "Du har ikke gemte \u00e6ndringer. Er du sikker p\u00e5 at du vil forts\u00e6tte?",
    "Restore last draft": "Genopret sidste kladde",
    "Special character": "Specielle tegn",
    "Source code": "Kildekode",
    "Insert\/Edit code sample": "Inds\u00e6t\/Ret kodeeksempel",
    "Language": "Sprog",
    "Code sample": "Kodepr\u00f8ve",
    "Color": "Farve",
    "R": "R",
    "G": "G",
    "B": "B",
    "Left to right": "Venstre til h\u00f8jre",
    "Right to left": "H\u00f8jre til venstre",
    "Emoticons": "Emot-ikoner",
    "Document properties": "Dokument egenskaber",
    "Title": "Titel",
    "Keywords": "S\u00f8geord",
    "Description": "Beskrivelse",
    "Robots": "Robotter",
    "Author": "Forfatter",
    "Encoding": "Kodning",
    "Fullscreen": "Fuldsk\u00e6rm",
    "Action": "Handling",
    "Shortcut": "Genvej",
    "Help": "Hj\u00e6lp",
    "Address": "Adresse",
    "Focus to menubar": "Fokus p\u00e5 menulinjen",
    "Focus to toolbar": "Fokus p\u00e5 v\u00e6rkt\u00f8jslinjen",
    "Focus to element path": "Fokuser p\u00e5 elementvej",
    "Focus to contextual toolbar": "Fokuser p\u00e5 kontekstuelle v\u00e6rkt\u00f8jslinje",
    "Insert link (if link plugin activated)": "Inds\u00e6t link (hvis link plugin er aktiveret)",
    "Save (if save plugin activated)": "Gem (hvis save plugin er aktiveret)",
    "Find (if searchreplace plugin activated)": "Find (hvis searchreplace plugin er aktiveret)",
    "Plugins installed ({0}):": "Installerede plugins ({0}):",
    "Premium plugins:": "Premium plugins:",
    "Learn more...": "L\u00e6r mere...",
    "More...": "Links, lister og billeder",
    "You are using {0}": "Du benytter {0}",
    "Plugins": "Plugins",
    "Handy Shortcuts": "Handy Genveje",
    "Horizontal line": "Vandret linie",
    "Insert\/edit image": "Inds\u00e6t\/ret billede",
    "Image description": "Billede-beskrivelse",
    "Source": "Link",
    "Dimensions": "Dimensioner",
    "Constrain proportions": "Behold propertioner",
    "General": "Generet",
    "Advanced": "Avanceret",
    "Style": "Stil",
    "Vertical space": "Lodret afstand",
    "Horizontal space": "Vandret afstand",
    "Border": "Kant",
    "Insert image": "Inds\u00e6t billede",
    "Image": "Billede",
    "Image...": "Billede...",
    "Image list": "Billede liste",
    "Rotate counterclockwise": "Drej modsat urets retning",
    "Rotate clockwise": "Drej med urets retning",
    "Flip vertically": "Flip vertikalt",
    "Flip horizontally": "Flip horisontalt",
    "Edit image": "Rediger billede",
    "Image options": "Billede indstillinger",
    "Zoom in": "Zoom ind",
    "Zoom out": "Zoom ud",
    "Crop": "Besk\u00e6r",
    "Resize": "Skaler",
    "Orientation": "Retning",
    "Brightness": "Lysstyrke",
    "Sharpen": "G\u00f8r skarpere",
    "Contrast": "Kontrast",
    "Color levels": "Farve niveauer",
    "Gamma": "Gamma",
    "Invert": "Inverter",
    "Apply": "Anvend",
    "Back": "Tilbage",
    "Insert date\/time": "Inds\u00e6t dato\/klokkeslet",
    "Date\/time": "Dato\/klokkeslet",
    "Insert link": "Inds\u00e6t link",
    "Insert\/edit link": "Inds\u00e6t\/ret link",
    "Text to display": "Tekst",
    "Url": "Url",
    "Yes": "Ja",
    "No": "Nej",
    "Target": "Target",
    "None": "Ingen",
    "New window": "Nyt vindue",
    "Current window": "Dette vindue",
    "Open link in...": "Åben link i...",
    "Remove link": "Fjern link",
    "Anchors": "Ankre",
    "Link": "Link",
    "Paste or type a link": "Inds\u00e6t eller skriv et link",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "URLen som du angav ser ud til at v\u00e6re en email adresse. \u00d8nsker du at tilf\u00f8je det kr\u00e6vede prefiks  mailto: ?",
    "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "URLen som du angav ser ud til at v\u00e6re et eksternt link. \u00d8nsker du at tilf\u00f8je det kr\u00e6vede prefiks http:\/\/ ?",
    "Link list": "Link liste",
    "Insert video": "Inds\u00e6t video",
    "Insert\/edit video": "Inds\u00e6t\/ret video",
    "Insert\/edit media": "Inds\u00e6t\/ret medier",
    "Alternative source": "Alternativ kilde",
    "Poster": "Poster",
    "Paste your embed code below:": "Inds\u00e6t din embed kode herunder:",
    "Embed": "Integrer",
    "Media": "Medier",
    "Nonbreaking space": "H\u00e5rdt mellemrum",
    "Page break": "Sideskift",
    "Paste as text": "Inds\u00e6t som ren tekst",
    "Preview": "Forh\u00e5ndsvisning",
    "Print": "Udskriv",
    "Save": "Gem",
    "Find": "Find",
    "Replace with": "Erstat med",
    "Replace": "Erstat",
    "Replace all": "Erstat alt",
    "Prev": "Forrige",
    "Next": "N\u00e6ste",
    "Find and replace": "Find og erstat",
    "Could not find the specified string.": "Kunne ikke finde s\u00f8getekst",
    "Match case": "STORE og sm\u00e5 bogstaver",
    "Whole words": "Hele ord",
    "Spellcheck": "Stavekontrol",
    "Ignore": "Ignorer",
    "Ignore all": "Ignorer alt",
    "Finish": "F\u00e6rdig",
    "Add to Dictionary": "Tilf\u00f8j til ordbog",
    "Insert table": "Inds\u00e6t tabel",
    "Table properties": "Tabel egenskaber",
    "Delete table": "Slet tabel",
    "Cell": "Celle",
    "Row": "R\u00e6kke",
    "Column": "Kolonne",
    "Cell properties": "Celle egenskaber",
    "Merge cells": "Flet celler",
    "Split cell": "Split celle",
    "Insert row before": "Inds\u00e6t r\u00e6kke f\u00f8r",
    "Insert row after": "Inds\u00e6t r\u00e6kke efter",
    "Delete row": "Slet r\u00e6kke",
    "Row properties": "R\u00e6kke egenskaber",
    "Cut row": "Klip r\u00e6kke",
    "Copy row": "Kopier r\u00e6kke",
    "Paste row before": "Inds\u00e6t r\u00e6kke f\u00f8r",
    "Paste row after": "Inds\u00e6t r\u00e6kke efter",
    "Insert column before": "Inds\u00e6t kolonne f\u00f8r",
    "Insert column after": "Inds\u00e6t kolonne efter",
    "Delete column": "Slet kolonne",
    "Cols": "Kolonne",
    "Rows": "R\u00e6kker",
    "Width": "Bredde",
    "Height": "H\u00f8jde",
    "Cell spacing": "Celle afstand",
    "Cell padding": "Celle padding",
    "Caption": "Tekst",
    "Left": "Venstre",
    "Center": "Centreret",
    "Right": "H\u00f8jre",
    "Cell type": "Celle type",
    "Scope": "Anvendelsesomr\u00e5de",
    "Alignment": "Tilpasning",
    "H Align": "H juster",
    "V Align": "V juster",
    "Top": "Top",
    "Middle": "Midt",
    "Bottom": "Bund",
    "Header cell": "Sidehoved celle",
    "Row group": "R\u00e6kke gruppe",
    "Column group": "Kolonne gruppe",
    "Row type": "R\u00e6kke type",
    "Header": "Sidehoved",
    "Body": "Krop",
    "Footer": "Sidefod",
    "Border color": "Kant farve",
    "Insert template": "Inds\u00e6t skabelon",
    "Templates": "Skabeloner",
    "Template": "Skabelon",
    "Text color": "Tekst farve",
    "Background color": "Baggrunds farve",
    "Custom...": "Brugerdefineret...",
    "Custom color": "Brugerdefineret farve",
    "No color": "Ingen farve",
    "Table of Contents": "Indholdsfortegnelse",
    "Show blocks": "Vis klokke",
    "Show invisible characters": "Vis usynlige tegn",
    "Words: {0}": "Ord: {0}",
    "{0} words": "ord",
    "File": "Fil",
    "Edit": "Rediger",
    "Insert": "Inds\u00e6t",
    "View": "Vis",
    "Format": "Format",
    "Table": "Tabel",
    "Tools": "V\u00e6rkt\u00f8j",
    "Powered by {0}": "Drevet af",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Rich Text omr\u00e5de. Tryk ALT-F9 for menu. Tryk ALT-F10 for toolbar. Tryk ALT-0 for hj\u00e6lp"
});