import { ImageCarousel } from "../utility/image-carousel";

let imageCarousel;

$(document).ready(function () {
    setForeningImgHeight();
    setupLatestImages();

    // Add 'hovered' class on mouse enter
    $(".foreningImg img").on("mouseenter", function () {
        $(this).parent().addClass("hovered");
    });

    // Remove 'hovered' class on mouse leave
    $(".foreningImg").on("mouseleave", function () {
        $(this).removeClass("hovered");
    });
});

$(window).resize(function () {
    setForeningImgHeight();
    imageCarousel.setHeight();
});

// Set height equal to width
let setForeningImgHeight = function () {
    let paragrapthHeight = $(".foreningImg > p").outerHeight();
    $(".community-container").css("height", $(".fi2").outerHeight() + paragrapthHeight + "px");
}

let setupLatestImages = function () {
    let latestImages = [];

    $('.latestImage').each(function (index, element) {
        latestImages[index] = $(element).attr("src");
    });

    imageCarousel = new ImageCarousel(
        "latestImages",
        latestImages
    );
}
