$(document).ready(function () {
    addDivs('table');
});

let addDivs = function (selector) {
    $(selector).each(function (index, element) {
        if (!$(element).parent().hasClass('simple-calendar')) {
            $(element).wrap('<div class="tableDiv"></div>');
        }
    });
}
