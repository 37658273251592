let hiddenSidebarSize;
let sidebar;

$(document).ready(function () {
    $("#pageDimmer").hide();
    getHiddenSize();
    sidebar = $('#sidebar');
    toggleMobileButton();

    $(window).resize(function () {
        getHiddenSize();
        positionSidebar();
    });

    $("#toggleSidebar, #toggleSidebarMobile").on("click", function () {
        toggleSidebar(sidebar, hiddenSidebarSize);
        toggleMobileButton();
    });

    $('#pageDimmer').on('click', function () {
       toggleSidebar($('#sidebar'), hiddenSidebarSize);
       toggleMobileButton();
    });

    let toggleSidebar = function () {
        if (sidebar.css("left") == "-" + hiddenSidebarSize +"px") {
            $('.cursorHint').hide();
            sidebar.css("left", "0px");
            sidebar.addClass("extended");
            $("#toggleSidebar img").attr("src", "/icons/close-menu.svg");
            $("#pageDimmer").fadeIn();
            $('.sidebar-dropdown-container').removeClass('extended');
            $('.sidebar-dropdown-container').hide();
            $('.sidebar-dropdown').attr('src', '/icons/chevron-down.svg');
        } else if (sidebar.css("left") == "0px") {
            sidebar.css("left", "-" + hiddenSidebarSize + "px");
            sidebar.removeClass("extended");
            $("#toggleSidebar img, #toggleSidebarMobile img").attr("src", "/icons/menu.svg");
            $("#pageDimmer").fadeOut();
        }
    }
});

let getHiddenSize = function () {
    if ($('body').outerWidth() > 950) {
        hiddenSidebarSize = 200;
    } else {
        hiddenSidebarSize = 300;
    }
}

let positionSidebar = function () {
    if (!sidebar.hasClass('extended')) {
        sidebar.css('left', '-' + hiddenSidebarSize + 'px');
    }
}

let toggleMobileButton = function () {
    if ($("body").outerWidth() < 950) {
        if (!sidebar.hasClass('extended')) {
           $('#toggleSidebarMobile').show();
        } else {
            $('#toggleSidebarMobile').hide();
        }
    }
}

