$(document).ready(function () {
    disableTopAndBottomArrows();

    // Change link position, when an arrow is clicked
    $(document).on('click', '#community-pages .change-page-order img', function () {
        if (!$(this).hasClass('disabled')) {
            let clickedElement = $(this);
            let clickedParent = $(this).parent().parent();

            if (clickedElement.hasClass('move-up')) {
                let prevElement = clickedParent.prev();

                clickedParent.animate({top: '-45px'}, 300, function(){

                });
                prevElement.animate({top: '45px'}, 300, function(){
                    clickedParent.css('top', '0px');
                    prevElement.css('top', '0px');
                    clickedParent.insertBefore(prevElement);

                    updatePageOrder(
                        clickedParent.attr('class').split(" ")[1],
                        prevElement.attr('class').split(" ")[1]
                    );
                    disableTopAndBottomArrows();
                });
            } else if (clickedElement.hasClass('move-down')) {
                let nextElement = clickedParent.next();

                clickedParent.animate({top: '45px'}, 300, function(){

                });
                nextElement.animate({top: '-45px'}, 300, function(){
                    clickedParent.css('top', '0px');
                    nextElement.css('top', '0px');
                    clickedParent.insertAfter(nextElement);

                    updatePageOrder(
                        clickedParent.attr('class').split(" ")[1],
                        nextElement.attr('class').split(" ")[1]
                    );
                    disableTopAndBottomArrows();
                });
            };
        };
    });
});

export let disableTopAndBottomArrows = function () {
    let firstArrow = $('#community-pages .change-page-order:first img:first');
    let secondArrow = $('#community-pages .change-page-order:last img:last')

    // Remove disabled class from all elements
    $('#community-pages .change-page-order img').removeClass('disabled');

    // Add disabled class to the correct elements
    firstArrow.addClass('disabled');
    secondArrow.addClass('disabled');
}

let updatePageOrder = function (clickedOrder, targetOrder) {
    let community_id = window.location.pathname.split("/")[2];
    let params = JSON.parse(
    '{ ' +
            '"clicked_order": "' + clickedOrder + '",' +
            '"target_order": "' + targetOrder + '",' +
            '"community_id": "' + community_id + '"' +
        '}'
    );

    $.get('/foreninger/side/opdater-orden', params, function (data) { });

    let clickedElement = $('.community-page-nav-item.' + clickedOrder);
    let targetElement = $('.community-page-nav-item.' + targetOrder);

    clickedElement.removeClass(clickedOrder);
    targetElement.removeClass(targetOrder);
    clickedElement.addClass(targetOrder);
    targetElement.addClass(clickedOrder);

    $('.community-page-nav-item.active').removeClass('active').addClass('active');
}