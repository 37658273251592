$(document).ready(function () {
   $('.card-button').on('click', function () {
       let currentRightX = $(this).css('right');

       if (currentRightX == "0px") {
           $('.card').addClass('extended');
           $("img", this).attr("src", "/icons/chevron-right.svg");
       } else {
           $('.card').removeClass('extended');
           $("img", this).attr("src", "/icons/chevron-left.svg");
       }
   });

   $('.card-button').on('mouseenter', function () {
      $(this).parent().css('border-color', '#888');
   });

    $('.card-button').on('mouseleave', function () {
        $(this).parent().css('border-color', 'rgba(34, 36, 38, 0.15)');
    });

   $(window).on('scroll', function () {
        if ($('body').innerWidth() <= "1550") {
            positionCard();
        } else {
            resetCard();
        }
   });

    $(window).on('resize', function () {
        if ($('body').innerWidth() <= "1550") {
            positionCard();
        } else {
            resetCard();
        }
    });

   let positionCard = function () {
       let scrollTop = $(window).scrollTop();

       if (scrollTop > 105) {
           if ($('body').innerWidth() > "1200") {
               $('.card').css('top', '28px');
               $('.card-button').css('top', '76px');
           } else {
               $('.card').css('top', '0px');
               $('.card').css('margin-top', '0px');
               $('.card-button').css('top', '28px');
           }
       } else {
           if ($('body').innerWidth() > "1200") {
               $('.card').css('top', (132 - scrollTop) + 'px');
               $('.card-button').css('top', (180 - scrollTop) + 'px');
           } else {
               $('.card').css('top', (84 - scrollTop) + 'px');
               $('.card').css('margin-top', '21px');
               $('.card-button').css('top', (130 - scrollTop) + 'px');
           }
       }
   }

   let resetCard = function () {
       $('.card').css('top', '0px');
   }

    if ($('body').innerWidth() <= "1550") {
        positionCard();
    } else {
        resetCard();
    }
});
