import {vanillaSelectBox} from "../../javascript/utility/vanilla-select-box";

// Multi select field configuration
$(document).ready(function () {
    if (window.location.pathname == "/nyhedsbreve/ny") {
        let multiSelect = new vanillaSelectBox("#multiCommunitySelect");
        let caretRotation = 0;
        let label = $('#multiCommunitySelect').hasClass('altLabel') ? "Hvilke foreninger vil du modtage nyhedsbreve fra?" : "Hvilke foreninger skal nyhedsbrevet sendes fra?";

        // Hide the original multi select field
        $("#multiCommunitySelect").hide();

        // New field setup
        $("#btn-group-\\#multiCommunitySelect.vsb-main > button").next().hide();
        $("#btn-group-\\#multiCommunitySelect.vsb-main > button > .title").text(label);
        setSpanColor(label);

        $("#btn-group-\\#multiCommunitySelect.vsb-main > button").on("click", function () {
            // If the field is empty, insert label
            if ($("#btn-group-\\#multiCommunitySelect.vsb-main > button > .title").text().length == 0) {
                $("#btn-group-\\#multiCommunitySelect.vsb-main > button > .title").text(label);
            }

            // Rotate dropdown caret
            caretRotation = (caretRotation + 180) % 360;
            $(this).next().toggle();
            $("#btn-group-\\#multiCommunitySelect.vsb-main button > span.caret").css("transform", "rotate(" + caretRotation + "deg)");
        });

        $("#btn-group-\\#multiCommunitySelect.vsb-main, .vsb-menu").on("click", function () {
            setSpanColor(label);
        });
    }
});

// Set span color based on if it acts as a label or if it contains values
let setSpanColor = function (label) {
    if ($("#btn-group-\\#multiCommunitySelect.vsb-main > button > .title").text() == label) {
        $("#btn-group-\\#multiCommunitySelect.vsb-main > button > span").css("color", "#727272");
    } else {
        $("#btn-group-\\#multiCommunitySelect.vsb-main > button > span").css("color", "#000");
    }
}
