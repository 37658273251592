$(document).ready(function () {
    $('.sidebar-dropdown-container').hide();

    $('.sidebar-community-toggle').on('click', function () {
        $('.sidebar-dropdown-container').toggleClass('extended');

        if ($('.sidebar-dropdown-container').hasClass('extended')) {
            $('.sidebar-dropdown-container').show();
            $('.sidebar-dropdown').attr('src', '/icons/chevron-up.svg');
        } else {
            $('.sidebar-dropdown-container').hide();
            $('.sidebar-dropdown').attr('src', '/icons/chevron-down.svg');
        };
    });
});